import React, { Component } from 'react'



class Card extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }
  render() {
    return (
      <div>
        <a href={this.props.url} target='_blank'>
          <div className="card">
              {this.props.title} * {this.props.name}
          </div>
        </a>
      </div>
    );
  }
}

export default Card;
