import Ryan from './static/Ryan.png'
import Yifu from './static/Yifu.png'
import zhaojing from './static/Zhaojing.png'
import Jessie from './static/Jessie.png'
import Sunnie from './static/Sunnie.png'
import Sharrie from './static/Sharrie.png'
import Jing from './static/Jing.png'
import Elif from './static/Elif.png'
import Luenne from './static/Luenne.png'
import Rajlaxmi from './static/Rajlaxmi.png'
import Stephany from './static/Stephany.png'
import Cleo from './static/Cleo.png'
import Sanchi from './static/Sanchi.png'
import Yuri from './static/Yuri.png'
import Shuqi from './static/Shuqi.png'


export const data = [
  {
    id: 14,
    name: 'Stephany Madi',
    title: 'Halal Carts: Understanding the Vernacular and the Branded in Street Food Culture',
    img: Stephany,
    url: 'https://medium.com/@madis336/halal-carts-59376646e64'
  },
  {
    id: 0,
    name: 'Ryan Fan',
    title: 'The Gradual Disappearance of Tactile Interaction in the Driving Experience',
    img: Ryan,
    url: 'https://uxdesign.cc/the-gradual-disappearance-of-tactile-interaction-in-the-driving-experience-fe894f83188a'
  },
  {
    id: 1,
    name: 'Zhaojing Feng',
    title: 'The Evolution of Interaction Design',
    img: zhaojing,
    url: 'https://medium.com/@fengz569/the-evolution-of-interaction-design-3eed1db992f6'
  },
  {
    id: 2,
    name: 'Jessie Yao',
    title: 'The Changing Design Language of Chinese Menu Design in East Village',
    img: Jessie,
    url: 'https://medium.com/@yaoj003/the-changing-design-language-of-chinese-menu-design-in-east-village-4c2d3987c0ae'
  },
  {
    id: 3,
    name: 'Yifu Zhang',
    title: 'Overweighted System Design and Oppressed Content',
    img: Yifu,
    url: 'https://medium.com/@zhany311/overweighted-system-design-and-oppressed-content-65b41a5cfdd6'
  },
  {
    id: 4,
    name: 'Sunnie Lee',
    title: 'The Printed Book',
    img: Sunnie,
    url: 'https://medium.com/@leesunnie/the-printed-book-a23b63bc3859?sk=abd7a6375a32a74a883eeacd2189e4eb'
  },
  {
    id: 5,
    name: 'Sharrie Li',
    title: 'Tom & Jerry are creating new: those Cartoon elements in fashion',
    img: Sharrie,
    url: 'https://medium.com/@lix961/tom-jerry-are-creating-new-what-could-we-get-from-those-cartoon-elements-f1795f4f021a'
  },
  {
    id: 6,
    name: 'Jing Gu',
    title: 'Color Trend for Technology',
    img: Jing,
    url: 'https://medium.com/@guj201/color-trend-for-technology-701eb749249b'
  },
  {
    id: 7,
    name: 'Elif Karakose',
    title: 'The Role of AR in City Installations for Creating Immersive Experience: Case of the Stonewall Forever and the AR(t) Walks in NYC',
    img: Elif,
    url: 'https://medium.com/@karakoseelif/the-role-of-ar-in-urban-art-installations-for-creating-immersive-experience-e88efbc78b6e?sk=3389c2063bdd7013a3ae529701a61710'
  },
  {
    id: 8,
    name: 'Luenne Neri',
    title: 'The controversies of designing digital products to empower women.',
    img: Luenne,
    url: 'https://medium.com/@luenne.neri/the-controversies-of-designing-digital-products-to-empower-women-e4ee1ae8f85c'
  },
  {
    id: 9,
    name: 'Cleo Peng',
    title: 'The best and worst of app design',
    img: Cleo,
    url: 'https://medium.com/@pengs709/the-best-and-worst-of-app-design-528dc1121550?sk=f75d03b620a02014e1cbd51021f60a80'
  },
  {
    id: 10,
    name: 'Rajlaxmi Jain',
    title: 'Museums in the Experience Economy',
    img: Rajlaxmi,
    url: 'https://medium.com/@hello_90218/museums-in-the-experience-economy-1afd45626467'
  },
  {
    id: 11,
    name: 'Sanchi Oberoi',
    title: 'What makes social media design by non-designers successful and what we can learn from it.',
    img: Sanchi,
    url: 'https://medium.com/@obers569/what-makes-social-media-design-by-non-designers-successful-and-what-we-can-learn-from-it-c892f02a8e3f'
  },
  {
    id: 12,
    name: 'Yuri Ha',
    title: 'Why Are Photographers Returning to Analogue Cameras in the Age of Instagram?',
    img: Yuri,
    url: 'https://medium.com/@hay523/my-experience-with-film-photography-began-without-an-aesthetic-reasoning-behind-but-in-pure-44c48412df0c'
  },
  {
    id: 13,
    name: 'Shuqi Zhu',
    title: 'The Rebirth of a Utopian Style',
    img: Shuqi,
    url: 'https://medium.com/@zhus066/the-rebirth-of-a-utopian-style-3f2dfbf045a9'
  }

]
