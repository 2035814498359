import * as PIXI from 'pixi.js'
import { data } from './data.js'

var app,stage,
    h,
    w,
    coord = [0,0],
    items = [],
    order = 0,
    mobile = false,
    active = false

class render {

  init() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      mobile = true
    }
    app = new PIXI.Application({ transparent: true, antialias: true, backgroundColor: 0x000000 });
    document.querySelector('.canvas').appendChild(app.view);
    app.view.style.position = 'fixed';
    app.view.style.zIndex = '-999';
    app.sortableChildren = true;

    app.view.width = window.innerWidth;
    app.view.height = window.innerHeight;
    app.view.style.top = 0;

    window.addEventListener('resize', resize);

    function resize () {
      app.view.width = window.innerWidth;
      app.view.height = window.innerHeight;
      w = app.view.width
      h = app.view.height
    }

    stage = new PIXI.﻿Container();﻿﻿﻿﻿﻿﻿
    stage.sortableChildren = true;﻿
    app.stage.addChild(stage);﻿﻿﻿﻿

    resize()

    stage = new PIXI.﻿Container();﻿﻿﻿﻿﻿﻿
    stage.sortableChildren = true;﻿
    app.stage.addChild(stage);﻿﻿﻿﻿

    document.onmousemove = (e) => {
      coord[0] = e.pageX
      coord[1] = e.pageY
    }

    //create sprite
    data.forEach(i => {
      let sprite = PIXI.Sprite.from(i.img)
      sprite.archive = sprite.texture
      sprite.x = Math.random()*w
      sprite.y = Math.random()*h
      sprite.width = h/5
      sprite.height = h/5
      let deg = Math.random()*360
      sprite.vx = 3*Math.sin(deg)
      sprite.vy = 3*Math.cos(deg)
      sprite.anchor.set(0.5);
      sprite.active = false;
      sprite.drag = false;
      sprite.title = i.title
      sprite.name = i.name
      sprite.id = i.id
      sprite.url = i.url
      sprite.rotation = Math.random()*360
      items.push(sprite)
      stage.addChild(sprite)
    })

    items.forEach(i => {

    })

    app.ticker.add((delta) => {

      items.forEach(i => {

        if(!i.interactive){
          i.interactive = true;
          i
            .on('pointerover', onHover)
            .on('pointerout', onLeave)
            .on('pointerdown', onDragStart)
            .on('pointerup', onDragEnd)
            .on('pointerupoutside', onDragEnd)
            .on('pointermove', onDragMove);
        }
        if(!i.active) {

          i.x += active ? i.vx/5 : i.vx
          i.y += active ? i.vy/5 : i.vy
          if(i.x < -Math.abs(i.vx)*100 || i.x > w+Math.abs(i.vx)*100){
            i.vx = i.vx*-1
            order --
            i.zIndex = order
          }
          if(i.y < -Math.abs(i.vy)*100 || i.y > h+Math.abs(i.vy)*100){
            i.vy = i.vy*-1
            order --
            i.zIndex = order
          }

        }
        if(i.destroying)  {
          i.rotation += 1*delta
          i.width -= 5
          i.height -= 5
          if(i.height < 5) {
            stage.removeChild(i);
          }
        }
        i.rotation += active ? 0.001*delta : 0.01*delta

      })
    })

    function onHover() {
      active = true
      this.active = true;
      this.zIndex = 1
      document.querySelector('.title').innerText = this.title
      document.querySelector('.name').innerText = '*' + this.name + '*'
      if(!mobile) {

        this.width = h/3
        this.height = h/3
        document.querySelector('.hole').classList.add('holing')
        items.forEach(i => {
            i.texture = this.texture
        })
      }

    }
    function onLeave() {
      active = false
      this.width = h/5
      this.height = h/5
      this.active = false;
      this.zIndex = order
      order--
      document.querySelector('.title').textContent = ''
      document.querySelector('.name').textContent = ''
      document.querySelector('.hole').classList.remove('holing')
      items.forEach(i => {
          i.texture = i.archive
      })
    }
    function onDragStart(e) {
      this.active = true;
      this.data = e.data
      this.drag = true
      document.querySelector('.title').innerText = this.title
      document.querySelector('.name').innerText = '*' + this.name + '*'
      if(mobile) {
        this.width = h/3
        this.height = h/3
        active = true
        items.forEach(i => {
            i.texture = this.texture
        })
        document.querySelector('.hole').classList.add('holing')
      }
    }
    function onDragEnd() {
      this.drag = false
      if(mobile) {
        active = false
        this.width = h/5
        this.height = h/5
        items.forEach(i => {
            i.texture = i.archive
        })
        this.active = false;
        document.querySelector('.title').textContent = ''
        document.querySelector('.name').textContent = ''
        document.querySelector('.hole').classList.remove('holing')
      }
      if(w - this.x < 200 && this.y < 200) {
        this.destroying = true
        setTimeout(() => {
          if(!mobile){
            window.open(this.url,'_blank')
          }else {
            window.location = this.url
          }},900)
      }
    }
    function onDragMove() {
      if(this.drag){
        const newPos = this.data.getLocalPosition(this.parent);
        this.x = newPos.x;
        this.y = newPos.y;
      }
    }


  }

  reset() {
    items.forEach(i => {
        i.texture = i.archive
    })
  }

  set(e) {
    let tex
    items.forEach(i => {
        if(i.id === e) {
          tex = i.archive
        }
    })
    items.forEach(j => {
        j.texture = tex
    })
  }

}

export default render;
