import React, { Component } from 'react'
import './App.scss'
import hole from './static/icon/hole.gif'
import on from './static/icon/on.png'
import off from './static/icon/off.png'

import Card from './components/card.jsx'
import Render from './render.js'

import { data } from './data.js'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      render:{},
      switch: true
    }
  }

  componentDidMount() {
    let render = new Render
    this.setState({render:render},() => this.state.render.init())
  }

  trigger() {
    this.setState({switch:!this.state.switch})
    this.state.render.reset()
  }


  render() {
    return (
      <div className="App">
        <div className='bg'></div>
        <div className='canvas'></div>
        <div className='what'>{this.state.switch?'visual seminar culture 2019 collection':''}</div>
        <div className='text'>
          <div>
            <p className='title'></p>
            <p className='name'></p>
          </div>
        </div>
        <div className='hole'>
          <div>
          <img src={hole} />
          </div>
        </div>
        <div className='switch' onClick={() => this.trigger()}>
          <img src={
            this.state.switch
            ?on
            :off
          }/>
        </div>
        {
          this.state.switch
          ?''
          :<div className='dullversion'>
              {
                data.map(i =>
                  <div  onMouseEnter={() => this.state.render.set(i.id)}>
                  <Card title={i.title} name={i.name} url={i.url} />
                  </div>
                )
              }
              <div className='info'><p>Provide by Parsons School of Design VISUAL SEMINAR 2019 led by Tiff Hockin<br/><span style={{fontSize:'10px'}}>Designed and built by Yifu Zhang</span></p></div>
          </div>
        }
      </div>
    );
  }
}

export default App;
